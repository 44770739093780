import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./baseUrl";

const initialState = {
  about: {},
  loading: false,
  error: null,
};

export const fetchAbout = createAsyncThunk("about/fetchAbout", async () => {
  const response = await axios.get(`${baseUrl}/api/GroupHome/AboutUs/en`);
  return response.data;
});

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAbout.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.about = action.payload;
      })
      .addCase(fetchAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default aboutSlice.reducer;
