import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./responsive.css";
import { store } from "./data/store";
import { Provider } from "react-redux";
import FirstLoad from "./pages/FirstLoad";
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}
const root = ReactDOM.createRoot(rootElement);
const MainApp = (
  <Provider store={store}>
    <App />
  </Provider>
);
if (process.env.REACT_SNAP) {
  console.log("Rendering FirstLoad for React Snap pre-rendering");
  root.render(<FirstLoad />);
} else {
  root.render(MainApp);
}
// Log to verify environment variable during build
console.log("REACT_SNAP:", process.env.REACT_SNAP);
