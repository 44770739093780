import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookingForm from "../../components/BookingForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchFacilities } from "./../../data/facilities/facilitiesSlice";
const Facilities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { facilities, loading, error } = useSelector(
    (state) => state.facilities
  );
  useEffect(() => {
    dispatch(fetchFacilities(hotelUrl));
  }, [dispatch, hotelUrl]);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {facilities?.pageDetails.pageMetatagTitle || "Facilities"}
            </title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/en/${hotelUrl}/facilities`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={facilities?.pageDetails.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={facilities.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? facilities?.pageDetails.pageBannerMobile
                  : isTablet
                  ? facilities?.pageDetails.pageBannerTablet
                  : facilities?.pageDetails.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1>{facilities?.pageDetails.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Facilities
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    <div className="text-15 uppercase mb-20">
                      <b> Our Activities</b>
                    </div>
                    <h2 className="text-50 lg:text-40 md:text-30">
                      {facilities?.pageDetails.pageTitle}
                    </h2>
                    <p>{facilities?.pageDetails.pageText}</p>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section className="services pb-5 mb-5">
            <div className="container">
              {facilities.facilities?.map((facility, index) =>
                index % 2 === 0 ? (
                  <div
                    className="row"
                    key={facility.facilityUrl}
                    id={facility.facilityUrl}
                  >
                    <div className="col-md-6 p-0 ">
                      <div className="img left">
                        <Link
                          to={`${
                            facility.isMoreDetails
                              ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                              : "#."
                          }`}
                        >
                          <img
                            src={facility.facilityPhoto}
                            alt={facility.facilityName}
                          />
                        </Link>
                      </div>
                    </div>

                    <div
                      className="col-md-6 p-0 bg-cream valign "
                      data-animate-effect="fadeInRight"
                    >
                      <div className="content">
                        <div className="cont text-left">
                          <div className="info">
                            <h6>Activities</h6>
                          </div>
                          <h4>{facility.facilityName}</h4>
                          <p
                            className={`${
                              !facility.isMoreDetails && "view-all"
                            }`}
                          >
                            {facility.facilitySummery}
                          </p>
                          {facility.isMoreDetails && (
                            <div className="butn-dark">
                              <Link
                                to={`/${facility.hotelUrl}/facilities/${facility.facilityUrl}`}
                              >
                                <span>Learn More </span>
                              </Link>{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="row d-flex flex-column-reverse flex-lg-row"
                    key={facility.facilityUrl}
                    id={facility.facilityUrl}
                  >
                    <div
                      className="col-md-6 p-0 bg-cream valign "
                      data-animate-effect="fadeInRight"
                    >
                      <div className="content">
                        <div className="cont text-left">
                          <div className="info">
                            <h6>Activities</h6>
                          </div>
                          <h4>{facility.facilityName}</h4>
                          <p
                            className={`${
                              !facility.isMoreDetails && "view-all"
                            }`}
                          >
                            {facility.facilitySummery}
                          </p>
                          {facility.isMoreDetails && (
                            <div className="butn-dark">
                              {" "}
                              <Link
                                to={`/${facility.hotelUrl}/facilities/${facility.facilityUrl}`}
                              >
                                <span>Learn More </span>
                              </Link>{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                      <div className="img right">
                        <Link
                          to={`${
                            facility.isMoreDetails
                              ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                              : "#."
                          }`}
                        >
                          <img
                            src={facility.facilityPhoto}
                            alt={facility.facilityName}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Facilities;
