import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";

import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Button } from "react-bootstrap";
import {
  faChevronCircleDown,
  faPlugCirclePlus,
  faPlusCircle,
  faSearch,
  faSearchMinus,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const BookingForm = () => {
  const { properties, loading, error } = useSelector(
    (state) => state.properties
  );
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [viewBooking, setViewBooking] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [childAges, setChildAges] = useState([]);

  // Calculate maximum children based on adults
  const maxChildren = adults >= 2 ? 4 - adults : adults === 1 && 3 - adults;
  useEffect(() => {
    if (children > maxChildren) {
      setChildren(maxChildren);
      setChildAges(childAges.slice(0, maxChildren));
    }
  }, [adults, maxChildren, childAges, children]);
  // Handle the number of children change
  const handleChildrenChange = (value) => {
    const newValue = Math.max(0, Math.min(maxChildren, Number(value)));
    setChildren(newValue);

    // Update child ages array if necessary
    if (newValue > childAges.length) {
      setChildAges([...childAges, ""]);
    } else if (newValue < childAges.length) {
      setChildAges(childAges.slice(0, newValue));
    }
  };

  // Handle age change for a specific child
  const handleAgeChange = (index, age) => {
    const newAges = [...childAges];
    newAges[index] = age;
    setChildAges(newAges);
  };

  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(
    new Date(checkInDate.getTime() + 24 * 60 * 60 * 1000)
  ); // Default to one day after check-in
  // const hotelUrl = localStorage.getItem("hotelUrl");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(checkInDate.getTime() + 24 * 60 * 60 * 1000)
  );
  const [selectedProperty, setSelectedProperty] = useState("");
  const [property, setProperty] = useState([0]);

  const updateSelectedProperty = () => {
    const path = window.location.pathname;
    if (path.includes("/stella-beach-resort-spa")) {
      setSelectedProperty("egcai29048");
    } else if (path.includes("/stella-gardens-resort-spa")) {
      setSelectedProperty("egcai29049");
    } else {
      setSelectedProperty("egstellamakadihotels");
    }
  };
  useEffect(() => {
    setProperty(properties);

    updateSelectedProperty();
  }, [properties]);

  useEffect(() => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    if (currentUrl.pathname.includes("/stella-beach-resort-spa")) {
      localStorage.setItem("hotelUrl", "stella-beach-resort-spa");
    } else if (currentUrl.pathname.includes("/stella-gardens-resort-spa")) {
      localStorage.setItem("hotelUrl", "stella-gardens-resort-spa");
    } else {
      localStorage.removeItem("hotelUrl");
    }
  }, []);
  const handleChange = (event) => {
    setSelectedProperty(event.target.value);
  };
  // Handler to update check-in date and automatically set check-out date to the next day
  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    setCheckOutDate(new Date(date.getTime() + 24 * 60 * 60 * 1000));
  };

  // Handler to update check-out date
  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
  };
  console.log(childAges, childAges[0], childAges[1]);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.post("https://api.orienthg.com/api/PMS", {
        propertyName: properties.find((p) => p.pmsCode === selectedProperty)
          ?.propertyName,
        pmsCode: selectedProperty,
        arrival: startDate.toLocaleDateString(),
        departure: endDate.toLocaleDateString(),
        adults: adults.toString(),
        children: children.toString(),
        promoCode: promoCode,
        childrenAges1: childAges[0] ? childAges[0] : 0,
        childrenAges2: childAges[1] ? childAges[1] : 0,
      });

      window.location.href = response.data.bookingEngineUrl;
    } catch (error) {
      console.error("Failed to submit booking:", error);
      alert("Failed to submit booking");
    }
  };
  return (
    <section className="content-section no-spacing">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div
              className="butn-dark   my-3"
              style={{ display: isMobile ? "block" : "none" }}
            >
              <Link
                to="#."
                className=" d-flex justify-content-center align-items-center w-100 px-50 my-3"
                onClick={() => setViewBooking(!viewBooking)}
              >
                <span className="text-light">
                  <FontAwesomeIcon
                    icon={viewBooking ? faSearchMinus : faSearchPlus}
                    className="me-2"
                  />
                  {!viewBooking && "Book Now"}
                </span>
              </Link>
            </div>
            <div
              className="hotel-search"
              style={{ display: viewBooking || !isMobile ? "block" : "none" }}
            >
              <form>
                <div className="form-group">
                  <label>Orient Hotels</label>

                  <select value={selectedProperty} onChange={handleChange}>
                    {loading ? (
                      <option>Loading...</option>
                    ) : error ? (
                      <option>Error: {error}</option>
                    ) : (
                      property.map((property) => (
                        <option key={property.pmsCode} value={property.pmsCode}>
                          {property.propertyName}
                        </option>
                      ))
                    )}
                  </select>

                  {/*<select defaultValue={hotelUrl ? hotelUrl : "all"}>
                    <option value="all">All Hotels</option>
                    <option value="stella-beach-resort-spa">
                      stella-beach-resort-spa
                    </option>
                    <option value="stella-gardens-resort-spa">
                      Stella Gardens Resort & Spa
                    </option>
                  </select> */}
                </div>
                {/* <div className="form-group">
                  <label>Date Check-In</label>
                  <div className="input-group">
                    <DatePicker
                      selected={checkInDate}
                      onChange={handleCheckInDateChange}
                      minDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Date Check-Out</label>
                  <div className="input-group">
                    {" "}
                    <DatePicker
                      selected={checkOutDate}
                      onChange={handleCheckOutDateChange}
                      minDate={checkOutDate}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                  </div>
                </div>*/}
                <div className="form-group date">
                  <label>Date (Check In - Check Out) </label>
                  <div className="input-group">
                    <DatePicker
                      selected={startDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      minDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                      placeholderText="Select a date range"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group adult">
                  <label>Adults </label>
                  <div className="adult-mobile">
                    <button
                      type="button"
                      className="counter"
                      onClick={() => {
                        adults > 1 && setAdults(adults - 1);
                      }}
                    >
                      -
                    </button>
                    <input
                      required
                      type="number"
                      className="fs-5"
                      placeholder="Adult Number"
                      value={adults}
                      onChange={(e) =>
                        setAdults(Math.max(1, Number(e.target.value)))
                      }
                      min="1"
                      max="4"
                    />
                    <button
                      type="button"
                      className="counter"
                      onClick={() => adults < 4 && setAdults(adults + 1)}
                    >
                      +
                    </button>
                  </div>
                  <input
                    type="number"
                    value={adults}
                    onChange={(e) =>
                      setAdults(Math.max(1, Number(e.target.value)))
                    }
                    min="1"
                    max="4"
                    className="adult-desktop"
                  />
                </div>
                <div className="form-group child">
                  <label>Child </label>
                  <div className="child-mobile">
                    <button
                      type="button"
                      className="counter"
                      onClick={() => {
                        children > 0 && handleChildrenChange(children - 1);
                      }}
                    >
                      -
                    </button>
                    <input
                      required
                      type="number"
                      className="fs-5"
                      placeholder="Child Number"
                      value={children}
                      onChange={(e) => handleChildrenChange(e.target.value)}
                      min="0"
                      max={maxChildren}
                    />
                    <button
                      type="button"
                      className="counter"
                      onClick={() =>
                        children < maxChildren &&
                        handleChildrenChange(children + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                  <input
                    type="number"
                    min="0"
                    max={maxChildren}
                    placeholder="0"
                    value={children}
                    onChange={(e) => handleChildrenChange(e.target.value)}
                    className="child-desktop"
                  />
                </div>{" "}
                {childAges.map((age, index) => (
                  <div key={index} className="form-group  col-1">
                    <label>Child {index + 1} Age</label>
                    <select
                      value={age}
                      onChange={(e) => handleAgeChange(index, e.target.value)}
                      className="child-age-dropdown"
                      style={{}}
                    >
                      {[...Array(13).keys()].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
                <div className="form-group ">
                  <label>Promo Code </label>

                  <input
                    type="text"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className="form-control d-block  promo-code"
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="submit"
                    value="FIND NOW !"
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingForm;
