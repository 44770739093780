import React, { useEffect, useState } from "react";
// import { Container, ProgressBar } from "react-bootstrap";

const Loader = () => {
  const [now, setNow] = useState(0);

  useEffect(() => {
    now <= 95 && setTimeout(() => setNow(now + 10), [100]);
  }, [now]);

  return (
    <>
      {/* <div style={{ height: "100vh" }} className="d-flex align-items-center ">
        <div className="container mt-5 text-center">
    
          <div
            className="loader d-flex justify-content-center align-items-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ zIndex: "9999999", position: "fixed" }}>
              {" "}
              <img
                alt="loader"
                src="Spin.gif"
                width="100"
               
                style={{ filter: "brightness(0.7)" }}
              />
               
            </div>
          </div>
           
        </div>
          </div>*/}
      <div id="preloader">
        <div id="preloader-status">
          <div className="preloader-position loader">
            {" "}
            <img src="loader.png" alt="loader" />{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
