import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BookingForm from "../../components/BookingForm";
import { useNavigate } from "react-router-dom";

const OffersGroup = () => {
  const navigate = useNavigate();
  const options = {
    loop: true,
    margin: 10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <>
      <section
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="3"
        style={{ backgroundImage: 'url("img/slider/5.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left caption mt-90">
              <h1>Special Offer</h1>
            </div>
          </div>
        </div>
      </section>
      <BookingForm />
      <section className="py-5 mt-5">
        <div className="container">
          <div className="row  mx-auto justify-content-center text-center">
            <div className="col-lg-10">
              <div className="text-15 uppercase mb-20">Orient Hotels</div>
              <h2 className="text-50 lg:text-40 md:text-30">Special Offers</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5 " id="news">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="section-subtitle">
                <span>Best Offers</span>
              </div>
              <div className="section-title">Extra Services</div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatum ratione, repellat saepe architecto hic doloribus
                laboriosam blanditiis ex alias aliquam tenetur itaque animi in
                non ipsam a corporis vel natus!
              </p>

              <div className="reservations mb-30">
                <div className="icon">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p>For information</p>
                  <a href="tel:855-100-4444">855 100 4444</a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <OwlCarousel className="owl-theme" {...options}>
                <div className="pricing-card">
                  <img src="img/pricing/1.jpg" alt="" />
                  <div className="desc">
                    <div className="name">Offers 01</div>
                    <div className="amount">
                      $50 <span>/ month</span>
                    </div>
                    <ul className="list-unstyled list mb-4">
                      <li>
                        <i className="ti-check"></i> Hotel ut nisan duru
                      </li>
                      <li>
                        <i className="ti-check"></i> Orci miss natoque ince
                      </li>
                      <li>
                        <i className="ti-close unavailable"></i> Clean sorem
                        ipsum morbin
                      </li>
                    </ul>
                    <div className="form-group ">
                      <input
                        type="submit"
                        className="d-block w-100"
                        value="Learn More"
                        onClick={() => navigate("offer-details")}
                      />
                    </div>
                  </div>
                </div>
                <div className="pricing-card">
                  <img src="img/pricing/2.jpg" alt="" />
                  <div className="desc">
                    <div className="name">Offers 02</div>
                    <div className="amount">
                      $30 <span>/ daily</span>
                    </div>
                    <ul className="list-unstyled list mb-4">
                      <li>
                        <i className="ti-check"></i> Hotel ut nisan duru
                      </li>
                      <li>
                        <i className="ti-check"></i> Orci miss natoque ince
                      </li>
                      <li>
                        <i className="ti-close unavailable"></i> Clean sorem
                        ipsum morbin
                      </li>
                    </ul>
                    <div className="form-group ">
                      <input
                        type="submit"
                        className="d-block w-100"
                        value="Learn More"
                        onClick={() => navigate("offer-details")}
                      />
                    </div>
                  </div>
                </div>
                <div className="pricing-card">
                  <img src="img/pricing/3.jpg" alt="" />
                  <div className="desc">
                    <div className="name">Offers 03</div>
                    <div className="amount">
                      $30 <span>/ daily</span>
                    </div>
                    <ul className="list-unstyled list mb-4">
                      <li>
                        <i className="ti-check"></i> Hotel ut nisan duru
                      </li>
                      <li>
                        <i className="ti-check"></i> Orci miss natoque ince
                      </li>
                      <li>
                        <i className="ti-close unavailable"></i> Clean sorem
                        ipsum morbin
                      </li>
                    </ul>
                    <div className="form-group ">
                      <input
                        type="submit"
                        className="d-block w-100"
                        value="Learn More"
                        onClick={() => navigate("offer-details")}
                      />
                    </div>
                  </div>
                </div>
                <div className="pricing-card">
                  <img src="img/pricing/4.jpg" alt="" />
                  <div className="desc">
                    <div className="name">Offers 04</div>
                    <div className="amount">
                      $15 <span>/ daily</span>
                    </div>
                    <ul className="list-unstyled list mb-4">
                      <li>
                        <i className="ti-check"></i> Hotel ut nisan duru
                      </li>
                      <li>
                        <i className="ti-check"></i> Orci miss natoque ince
                      </li>
                      <li>
                        <i className="ti-close unavailable"></i> Clean sorem
                        ipsum morbin
                      </li>
                    </ul>
                    <div className="form-group ">
                      <input
                        type="submit"
                        className="d-block w-100"
                        value="Learn More"
                        onClick={() => navigate("offer-details")}
                      />
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OffersGroup;
