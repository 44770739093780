import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Loader from "./../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faGlobeAsia,
  faHome,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { fetchLayoutHotel } from "./../data/layoutHotelSlice";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { subscribeNewsletter } from "../data/newsLetterSlice";
import { Form } from "react-bootstrap";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const LayoutHotels = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const hotelUrl = localStorage.getItem("hotelUrl");
  const [language, setLanguage] = useState("EN");
  const { layoutHotel, loading, error } = useSelector(
    (state) => state.layoutHotel
  );
  const { hotels } = useSelector((state) => state.hotels);
  useEffect(() => {
    const hotelUrl = localStorage.getItem("hotelUrl");
    // Get the current URL
    if (!hotelUrl) {
      window.location.href = `/en`;
    }
  }, [hotelUrl]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLayoutHotel(hotelUrl));
  }, [dispatch, hotelUrl]);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  const navbarClassShow = navbarOpen ? "show" : "";
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Adding the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Removing the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  const { homeHotel } = useSelector((state) => state.homeHotel);
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const { loadingNewsletter, errorNewsletter } = useSelector(
    (state) => state.newsLetter
  );

  const [email, setEmail] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(subscribeNewsletter(email));
  };
  // Determine the navbar class and logo src based on scroll position
  const navbarClass = scrollY > 100 ? "nav-scroll" : "";
  const logoSrc =
    scrollY > 100
      ? layoutHotel.hotelHeader?.hotelLogoColored
      : layoutHotel.hotelHeader?.hotelLogo;
  const navigate = useNavigate();
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {/*Header*/}
          <nav className={`navbar navbar-expand-lg ${navbarClass}`}>
            <div
              className="container-fluid"
              style={{ justifyContent: "space-between" }}
            >
              <div className="logo-wrapper col-lg-2">
                <Link className="logo " to={`/${hotelUrl}`}>
                  <img
                    src={logoSrc}
                    className="logo-img logo-mobile logo-hotel"
                    alt="logo"
                    style={{ width: isMobile ? "160px" : "100%" }}
                  />{" "}
                </Link>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu"></i>
                </span>
              </button>

              <div
                className={`collapse navbar-collapse col-lg-10 ${navbarClassShow}`}
                id="navbar"
              >
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link"
                      to={`/${hotelUrl}`}
                      onClick={toggleNavbar}
                      role="button"
                    >
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={`/${hotelUrl}/accommodation`}
                      onClick={toggleNavbar}
                    >
                      Accommodation
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={`/${hotelUrl}/dining`}
                      onClick={() => {
                        navigate(`/${hotelUrl}/dining`);
                      }}
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      Dining{" "}
                      {layoutHotel.hotelHeader?.diningTypes.length > 0 && (
                        <i className="ti-angle-down"></i>
                      )}
                    </Link>
                    {layoutHotel.hotelHeader?.diningTypes.length > 0 && (
                      <ul className="dropdown-menu">
                        {layoutHotel.hotelHeader?.diningTypes?.map((dining) => (
                          <li key={dining.filterBy}>
                            <Link
                              to={`/${dining.hotelUrl}/${dining.filterBy}`}
                              className="dropdown-item"
                              onClick={toggleNavbar}
                            >
                              <span>{dining.restaurantsTypeName} </span>
                            </Link>
                          </li>
                        ))}
                        {layoutHotel.hotelHeader?.isEnableAllInclusive && (
                          <li>
                            <Link
                              to={`/${hotelUrl}/all-inclusive`}
                              onClick={toggleNavbar}
                            >
                              All Inclusive
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={`/${hotelUrl}/facilities`}
                      onClick={toggleNavbar}
                    >
                      Facilities
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={`/${hotelUrl}/meetingandevents`}
                      onClick={toggleNavbar}
                    >
                      Meeting & Events
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={`/${hotelUrl}/gallery`}
                      onClick={toggleNavbar}
                    >
                      Gallery
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={`/${hotelUrl}/contact-us`}
                      onClick={toggleNavbar}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>

                <ul
                  className="navbar-nav col-lg-3 ms-auto"
                  style={{ alignItems: "center", justifyContent: "right" }}
                >
                  <li className=" me-2"> </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#."
                      title="Orient Hotels"
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon
                        className={`home-icon ${isMobile && "text-dark"}`}
                        icon={faHome}
                      />{" "}
                      Hotels <i className={`ti-angle-down `}></i>
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to={`/ `}
                          onClick={() => localStorage.removeItem("hotelUrl")}
                          className="dropdown-item"
                        >
                          <span>
                            <FontAwesomeIcon className="me-2" icon={faHome} />
                            Orient Hotels{" "}
                          </span>
                        </Link>
                      </li>
                      {hotels?.map((hotel) => (
                        <li key={hotel.hotelUrl}>
                          <Link
                            to={`/${hotel.hotelUrl} `}
                            onClick={() =>
                              localStorage.setItem("hotelUrl", hotel.hotelUrl)
                            }
                            className="dropdown-item"
                          >
                            <span>
                              <FontAwesomeIcon
                                className="me-2"
                                icon={faArrowRight}
                              />
                              {hotel.hotelName}{" "}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li
                    className="nav-item dropdown me-2"
                    style={{ display: "none" }}
                  >
                    <Link
                      className="nav-link dropdown-toggle text-uppercase"
                      to="#."
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon icon={faGlobeAsia} /> {language}{" "}
                      <i className="ti-angle-down"></i>
                    </Link>
                    <ul className="dropdown-menu">
                      {layoutHotel.hotelHeader?.languages?.map(
                        (lang, index) => (
                          <li key={index}>
                            <Link
                              to="#."
                              cla
                              className="dropdown-item d-flex align-items-baseline "
                              onClick={() =>
                                setLanguage(lang.languageAbbreviation)
                              }
                            >
                              <span>
                                <img
                                  alt="flag"
                                  src={lang.languageFlag}
                                  className="pe-2"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />{" "}
                              </span>
                              <span>{lang.languageName}</span>
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </li>

                  <li>
                    <a
                      rel="noreferrer"
                      href={`https://www.book-secure.com/index.php?s=results&property=${homeHotel.hotelPmscode}&locale=en_GB&currency=USD`}
                      target="_blank"
                      className="form-group"
                    >
                      <input type="submit" value="Book Now !" />
                    </a>
                  </li>
                </ul>
                <div></div>
              </div>
            </div>
          </nav>
          <Outlet />
          {/*Footer*/}
          <footer className="footer">
            <div
              className="footer-overlay"
              style={{ backgroundImage: `url(footer_bg.png)` }}
            ></div>
            <div className="footer-top">
              <div className="container-fluid">
                <div className="row justify-content-lg-evenly px-lg-5">
                  <div className="col-md-3">
                    <div className="footer-column footer-about">
                      <img
                        alt=""
                        src={layoutHotel.hotelHeader?.hotelLogo}
                        style={{ width: "75%" }}
                        className="mb-4"
                      />
                      <p className="footer-about-text">
                        {layoutHotel.hotlFooter?.hotelSummery
                          ? layoutHotel.hotlFooter?.hotelSummery
                          : `Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley`}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-between justify-content-lg-evenly px-lg-5 flex-lg-wrap mb-4 mb-lg-0">
                    <div className="col-6 col-lg-5 offset-md-1">
                      <div className="footer-column footer-explore clearfix">
                        <h3 className="footer-title">Explore </h3>
                        <ul className="footer-explore-list list-unstyled">
                          <li>
                            <Link
                              className="text-light"
                              to={`/${hotelUrl}/accommodation`}
                            >
                              Accommodation
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="text-light"
                              to={`/${hotelUrl}/dining`}
                            >
                              Dining
                            </Link>
                          </li>

                          {layoutHotel.hotelHeader?.isEnableAllInclusive && (
                            <li>
                              <Link
                                className="text-light"
                                to={`/${hotelUrl}/all-inclusive`}
                              >
                                All Inclusive
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              className="text-light"
                              to={`/${hotelUrl}/facilities`}
                            >
                              Facilities
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="text-light"
                              to={`/${hotelUrl}/meetingandevents`}
                            >
                              Meeting & Events
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="text-light"
                              to={`/${hotelUrl}/gallery`}
                            >
                              Gallery
                            </Link>
                          </li>
                          {layoutHotel.hotelHeader?.isEnableFaq && (
                            <li>
                              <Link
                                className="text-light"
                                to={`/${hotelUrl}/faq`}
                              >
                                FAQ
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              className="text-light"
                              to={`/${hotelUrl}/contact-us`}
                            >
                              Contact Us{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-6 col-lg-5 offset-md-1">
                      <div className="footer-column footer-explore clearfix">
                        <h3
                          className="footer-title "
                          style={{ visibility: "hidden" }}
                        >
                          Explore{" "}
                        </h3>
                        <ul className="footer-explore-list list-unstyled">
                          <li>
                            <Link className="text-light" to="/">
                              Orient Hotels
                            </Link>
                          </li>
                          <li>
                            <Link className="text-light" to="/about-us">
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link className="text-light" to={`/nile-cruises`}>
                              Nile Cruises
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="text-light"
                              to={`/online-check-in`}
                            >
                              Online Check In
                            </Link>
                          </li>
                          <li>
                            <li>
                              <Link className="text-light" to="/privacy-policy">
                                Privacy Policy
                              </Link>
                            </li>
                          </li>
                          <li>
                            <Link className="text-light" to="/terms-conditions">
                              Terms & Conditions{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="footer-column footer-contact">
                      <h3 className="footer-title">Contact </h3>

                      <div className="footer-contact-info text-light">
                        <Link to={`tel:${layoutHotel.hotlFooter?.hotelPhone}`}>
                          <FontAwesomeIcon className="me-2" icon={faPhone} />
                          {layoutHotel.hotlFooter?.hotelPhone}
                        </Link>
                        <Link className="fs-5 mx-1 text-light" to="#.">
                          /
                        </Link>
                        <Link
                          to={`tel:${layoutHotel.hotlFooter?.hotelOtherPhone}`}
                        >
                          {layoutHotel.hotlFooter?.hotelOtherPhone}
                        </Link>
                        <br />

                        {layoutHotel.hotlFooter?.hotelWhatsAppNumber && (
                          <Link
                            to={`https://wa.me/${layoutHotel.hotlFooter?.hotelWhatsAppNumber}`}
                            target="_blank"
                            className="mt-lg-2"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faWhatsapp}
                            />
                            {layoutHotel.hotlFooter?.hotelWhatsAppNumber}
                          </Link>
                        )}

                        <Link
                          to={layoutHotel.hotlFooter?.hotelGetDirectionLink}
                          target="_blank"
                          className="mt-lg-2"
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon={faLocationDot}
                          />{" "}
                          {layoutHotel.hotlFooter?.hotelAddress}
                        </Link>
                        <Link
                          to={`mailto:${layoutHotel.hotlFooter?.hotelEmail}`}
                          className="footer-contact-mail mt-0"
                        >
                          <FontAwesomeIcon className="me-2" icon={faEnvelope} />
                          {layoutHotel.hotlFooter?.hotelEmail}
                        </Link>
                      </div>
                      <div className="footer-about-social-list">
                        {layoutHotel.hotlFooter.hotelSocials?.map(
                          (item, index) => (
                            <Link
                              target="_blank"
                              to={item.socialUrl}
                              title={item.socialName}
                              key={index}
                            >
                              <i className={`${item.socialClass}`}></i>
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="footer-column footer-contact">
                      <h3 className="footer-title">Newsletter</h3>

                      <p className="text-light">Subscribe our Newsletter </p>
                      <Form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            placeholder="Enter You'r Email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="Email"
                            className="ps-2"
                            style={{ border: "1px solid" }}
                          />
                          <input
                            type="submit"
                            value={`${
                              loadingNewsletter ? "Loading..." : "Subscribe Now"
                            }`}
                          />
                        </div>
                      </Form>
                      {!loadingNewsletter ? (
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity={`${
                              errorNewsletter ? "error" : "success"
                            }`}
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {errorNewsletter
                              ? errorNewsletter
                              : "Thank you for subscribing!"}
                          </Alert>
                        </Snackbar>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="container-fluid">
                <div className="row px-lg-5">
                  <div className="col-md-12">
                    <div className="footer-bottom-inner d-flex flex-column flex-lg-row justify-content-between">
                      <p
                        className="footer-bottom-copy-right mb-2 mb-lg-0"
                        dangerouslySetInnerHTML={{
                          __html: `${layoutHotel.hotlFooter?.copyrights}`,
                        }}
                      />

                      <p className="footer-bottom-copy-right">
                        Powered By{" "}
                        <Link to="https://titegypt.com/" target="_blank">
                          T.I.T Solutions
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default LayoutHotels;
