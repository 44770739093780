import React, { useEffect, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchContactHotel } from "./../../data/contactHotel/contactHotelSlice";
import {
  faArrowRight,
  faEnvelope,
  faLocationArrow,
  faLocationCrosshairs,
  faLocationDot,
  faMapLocationDot,
  faMapSigns,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
const ContactUsGroup = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { contactHotel, loading, error } = useSelector(
    (state) => state.contactHotel
  );
  const [hotelID, setHotelID] = useState("");
  const [response, setResponse] = useState("");
  useEffect(() => {
    dispatch(fetchContactHotel(hotelUrl));
  }, [dispatch, hotelUrl]);
  useEffect(() => {
    setHotelID(contactHotel.contactDetails?.hotelId);
  }, [contactHotel.contactDetails?.hotelId]);

  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customerMessage: "",
    hotelId: hotelID,
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from refreshing the page on submit

    const apiUrl = "https://api.orienthg.com/api/ContactUs/ContactUs-Submit";
    const data = {
      customerName: formData.customerName,
      customerEmail: formData.customerEmail,
      customerPhone: formData.customerPhone,
      customerMessage: formData.customerMessage,
      hotelId: hotelID,
    };

    try {
      const response = await axios.post(apiUrl, data);
      setResponse(response.data);
      setOpen(true);
      // Reset form fields to empty
      setFormData({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customerMessage: "",
      });
    } catch (error) {
      console.error("Error posting message:", error);
      // Handle errors here (e.g., notify user of an error)
      alert("Failed to send message. Please try again.");
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {contactHotel.pageDetails?.pageMetatagTitle || "Contact US"}
            </title>
            <link
              rel="canonical"
              href={`https://orienthg.com/en/${hotelUrl}/contact-us`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={contactHotel.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={
              contactHotel.pageDetails?.pageBannerColorOverlayTo
            }
            style={{
              backgroundImage: `url(${
                isMobile
                  ? contactHotel.pageDetails?.pageBannerMobile
                  : isTablet
                  ? contactHotel.pageDetails?.pageBannerTablet
                  : contactHotel.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    <h5>
                      <b>{contactHotel.pageDetails?.pageTitle}</b>{" "}
                    </h5>
                    <h1>{contactHotel.pageDetails?.pageText}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Contact Us
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <section className="contact section-padding">
            <div className="container">
              <div className="row mb-90">
                <div className="col-md-6 mb-60">
                  <h1>{contactHotel.contactDetails?.hotelContactUsTitle}</h1>
                  <p>{contactHotel.contactDetails?.hotelContactUsSummery}</p>
                  <div className="reservations mb-30">
                    <div className="icon">
                      <span className="flaticon-call"></span>
                    </div>
                    <div className="text">
                      <p>Reservation: </p>{" "}
                      <Link
                        className="fs-5"
                        to={`tel:${contactHotel.contactDetails?.hotelPhone}`}
                      >
                        {contactHotel.contactDetails?.hotelPhone}
                      </Link>{" "}
                      <Link className="fs-5 mx-2 text-dark" to="#.">
                        /
                      </Link>{" "}
                      <Link
                        className="fs-5"
                        to={`tel:${contactHotel.contactDetails?.hotelOtherPhone}`}
                      >
                        {contactHotel.contactDetails?.hotelOtherPhone}
                      </Link>
                    </div>
                  </div>
                  <div className="reservations mb-30">
                    <div className="icon">
                      <span className="flaticon-envelope"></span>
                    </div>
                    <div className="text">
                      <p>Email Info: </p>{" "}
                      <Link
                        className="fs-5"
                        to={`mailto:${contactHotel.contactDetails?.hotelEmail}`}
                      >
                        {contactHotel.contactDetails?.hotelEmail}
                      </Link>
                    </div>
                  </div>
                  <div className="reservations mb-30">
                    <div className="icon">
                      <span className="flaticon-location-pin"></span>
                    </div>
                    <div className="text">
                      <p>Address: </p>{" "}
                      <Link
                        className="fs-5"
                        to={contactHotel.contactDetails?.hotelGetDirectionLink}
                      >
                        {contactHotel.contactDetails?.hotelAddress}
                      </Link>
                    </div>
                  </div>
                  <div className="reservations">
                    <div className="text">
                      <h2>Hotel Near By: </h2>
                      <ul>
                        {contactHotel.hotelNearBy?.map((location, index) => (
                          <li className="fs-5 d-flex">
                            <b>
                              <FontAwesomeIcon
                                icon={faLocationCrosshairs}
                                className="me-2"
                              />
                            </b>{" "}
                            <p>
                              {location.hotelNearByName} (
                              {location.hotelNearByDistance}{" "}
                              {location.hotelNearByDistanceUnit}) .
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mb-30 offset-md-1">
                  <h1>Get in touch </h1>
                  <form className="contact__form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 form-group my-2">
                        <input
                          name="customerName"
                          className="py-3 px-2"
                          type="text"
                          placeholder="Your Name *"
                          required
                          value={formData.customerName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-12 form-group mb-2">
                        <input
                          name="customerPhone"
                          type="text"
                          placeholder="Your Mobile *"
                          required
                          value={formData.customerPhone}
                          onChange={handleChange}
                          className="py-3 px-2"
                        />
                      </div>
                      <div className="col-md-12 form-group mb-2">
                        <input
                          className="py-3 px-2"
                          name="customerEmail"
                          type="email"
                          placeholder="Your Email *"
                          required
                          value={formData.customerEmail}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-12 form-group mb-2">
                        <textarea
                          className="py-3 px-2"
                          name="customerMessage"
                          cols="30"
                          rows="4"
                          placeholder="Message *"
                          value={formData.customerMessage}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="butn-dark2">
                          <span>Send Message</span>
                        </button>
                        <Snackbar
                          open={open}
                          autoHideDuration={7000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            Your Message Sent Successfully , Thank You{" "}
                            {response.customerName}
                          </Alert>
                        </Snackbar>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12   ">
                  <iframe
                    title="Map"
                    src={contactHotel.contactDetails?.hotelGoogleMap}
                    width="100%"
                    height="600"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default ContactUsGroup;
