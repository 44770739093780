import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiningDetails } from "./../../data/dining/diningDetailsSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import {
  faClipboardList,
  faUserCheck,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const DiningDetails = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");

  const { restaurantUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { diningDetails, loading, error } = useSelector(
    (state) => state.diningDetails
  );

  useEffect(() => {
    dispatch(fetchDiningDetails({ hotelUrl, restaurantUrl }));
  }, [dispatch, restaurantUrl, hotelUrl]);
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions

    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    dots: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  const diningOptions = {
    margin: 40,
    loop: true,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{diningDetails.metatagTitle}</title>
            <meta
              name="description"
              content={diningDetails.metatagDescription}
            />
            <link
              rel="canonical"
              href={`https://www.orienthg.com/en/${hotelUrl}/${restaurantUrl}`}
            />
            <meta name="robots" content="index, follow" />
          </Helmet>
          <header className="header slider details">
            <OwlCarousel className="owl-theme" {...options}>
              {diningDetails.restaurantGalleries?.map((image, index) => (
                <div
                  key={index}
                  className="item bg-img text-center"
                  style={{
                    backgroundImage: `url(${image.photoFile})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              ))}
            </OwlCarousel>
            <div className="arrow bounce text-center">
              <Link to="#." onClick={(e) => scrollToSection("content")}>
                {" "}
                <i className="ti-arrow-down"></i>{" "}
              </Link>
            </div>
            <div className="owl-dots">
              <button role="button" className="owl-dot active">
                <span></span>
              </button>
              <button role="button" className="owl-dot">
                <span></span>
              </button>
              <button role="button" className="owl-dot">
                <span></span>
              </button>
            </div>
          </header>
          <div
            style={{ marginTop: "-28px" }}
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}/dining`}
                >
                  Dining
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {diningDetails.restaurantName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="rooms-page py-5 " id="content">
            <div className="container">
              <div className="row">
                <Fade triggerOnce cascade direction="up" duration="800">
                  <div className="col-md-12 text-left">
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <div className="section-subtitle">
                      {diningDetails.restaurantsTypeName}
                    </div>
                    <div className="section-title">
                      {diningDetails.restaurantName}
                    </div>
                    <p
                      className="mb-30"
                      dangerouslySetInnerHTML={{
                        __html: diningDetails.restaurantDetails,
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    {diningDetails.dressCode && (
                      <>
                        {" "}
                        <p className="mb-2">
                          <b>
                            <FontAwesomeIcon
                              icon={faUserCheck}
                              className="me-2"
                            />{" "}
                            Dress Code:{" "}
                          </b>
                          <span>{diningDetails.dressCode}</span>
                        </p>
                      </>
                    )}
                    {diningDetails.restaurantCuisine && (
                      <>
                        {" "}
                        <p className="mb-2">
                          <b>
                            <FontAwesomeIcon
                              icon={faUtensils}
                              className="me-2"
                            />{" "}
                            Cuisine Type:{" "}
                          </b>
                          <span>{diningDetails.restaurantCuisine}</span>
                        </p>
                      </>
                    )}
                    {diningDetails.restaurantOpenFor && (
                      <>
                        {" "}
                        <p className="mb-2">
                          <b>
                            <FontAwesomeIcon icon={faClock} className="me-2" />{" "}
                            Open For:{" "}
                          </b>
                          <span>{diningDetails.restaurantOpenFor}</span>
                        </p>
                      </>
                    )}
                    {diningDetails.restaurantMenu && (
                      <>
                        {" "}
                        <p className="mb-2">
                          <b>
                            <FontAwesomeIcon
                              icon={faClipboardList}
                              className="me-2"
                            />{" "}
                            Menu:{" "}
                          </b>
                          <span>{diningDetails.restaurantMenu}</span>
                        </p>
                      </>
                    )}
                    {diningDetails.restaurantNote && (
                      <>
                        {" "}
                        <h6 className=" ">
                          Notes:{" "}
                          <b>
                            <span>{diningDetails.restaurantNote}</span>
                          </b>
                        </h6>
                      </>
                    )}
                    {diningDetails.openinghours && (
                      <>
                        <h6 className="mt-5 mb-2">
                          <FontAwesomeIcon icon={faClock} className="me-2" />
                          Working Hours:{" "}
                        </h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ` ${diningDetails.openinghours}`,
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                </Fade>
              </div>{" "}
            </div>
          </section>

          <section className="rooms3 py-5 bg-cream" id="dining">
            <div className="container">
              <div className="row">
                <Fade direction="left" triggerOnce="true">
                  <div className="col-md-12">
                    <div className="section-subtitle">Explore</div>
                    <div className="section-title">Other Dining</div>
                  </div>
                </Fade>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel className="owl-theme" {...diningOptions}>
                    {diningDetails.otherRestaurants?.map((restaurant) => (
                      <div
                        key={restaurant.restaurantUrl}
                        className="square-flip"
                      >
                        <div
                          className="square bg-img"
                          style={{
                            backgroundImage: `url(${restaurant.restaurantPhoto})`,
                          }}
                        >
                          <span className="category">
                            <Link
                              to={`${
                                restaurant.isMoreDetails
                                  ? `/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`
                                  : `/${restaurant.hotelUrl}/dining#${restaurant.restaurantUrl}`
                              }`}
                            >
                              {restaurant.restaurantsTypeName}
                            </Link>
                          </span>
                          <div className="square-container d-flex align-items-end justify-content-end">
                            <div className="box-title me-auto">
                              <h4>{restaurant.restaurantName}</h4>
                            </div>
                          </div>
                          <div className="flip-overlay"></div>
                        </div>
                        <div className="square2 bg-white">
                          <div className="square-container2">
                            <h5 className="fw-bold">
                              {restaurant.restaurantName}
                            </h5>
                            <ul className="px-0 d-flex justify-content-left col-12 flex-wrap">
                              {restaurant.dressCode && (
                                <li className="w-auto me-2">
                                  <b>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faUserCheck}
                                      className="me-2"
                                    />
                                    Dress Code:{" "}
                                  </b>
                                  {restaurant.dressCode}
                                </li>
                              )}
                              {restaurant.restaurantCuisine && (
                                <li className="w-auto me-2">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faUtensils}
                                      className="me-2"
                                    />
                                    Cuisine:{" "}
                                  </b>
                                  {restaurant.restaurantCuisine}
                                </li>
                              )}
                              {restaurant.restaurantOpenFor && (
                                <li className="w-auto me-2">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faClock}
                                      className="me-2"
                                    />
                                    Open For:{" "}
                                  </b>
                                  {restaurant.restaurantOpenFor}
                                </li>
                              )}
                              {restaurant.restaurantMenu && (
                                <li className="w-auto me-2">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faClipboardList}
                                      className="me-2"
                                    />
                                    Menu:{" "}
                                  </b>
                                  {restaurant.restaurantMenu}
                                </li>
                              )}
                            </ul>
                            {restaurant.openinghours && (
                              <div className="col-12 col-lg-8">
                                {" "}
                                <h6 className="fw-bold  mb-1">
                                  <b>
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon={faClock}
                                    />
                                  </b>
                                  Opening Hours:
                                </h6>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `${restaurant.openinghours}`,
                                  }}
                                />
                              </div>
                            )}
                            <p>{restaurant.restaurantSummery}</p>
                            {restaurant.isMoreDetails && (
                              <div className="btn-line">
                                <Link
                                  to={`/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`}
                                >
                                  Details
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default DiningDetails;
