import React from "react";

const FirstLoad = () => {
  return (
    <section style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}>
      <h1 className="text-center text-light">Loading...</h1>
    </section>
  );
};

export default FirstLoad;
