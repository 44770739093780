import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BookingForm from "../components/BookingForm";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeGroup } from "../data/homeGroup/homeGroupSlice";
import { useMediaQuery } from "@mui/material";
import Loader from "./../Loader";
import { Helmet } from "react-helmet-async";
import { Fade } from "react-awesome-reveal";

const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};
const HomeGroup = () => {
  const dispatch = useDispatch();
  const { homeGroup, loading, error } = useSelector((state) => state.homeGroup);
  useEffect(() => {
    dispatch(fetchHomeGroup());
  }, [dispatch]);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const tiltRef1 = useRef(null);
  const tiltHandler1 = createTiltHandler(tiltRef1);
  const tiltRef2 = useRef(null);
  const tiltHandler2 = createTiltHandler(tiltRef2);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
  };
  const hotelsOptions = {
    margin: 10,
    loop: true,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const newsOptions = {
    loop: true,
    margin: 10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {homeGroup.metatagTitle ? homeGroup.metatagTitle : "Hotels"}
            </title>
            <link rel="canonical" href={`https://www.orienthg.com/en`} />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={
                homeGroup.metatagDescription ? homeGroup.metatagDescription : ""
              }
            />{" "}
            <link
              rel="stylesheet"
              property="stylesheet"
              href="//websdk.fastbooking-services.com/widgets/app.css"
            />
            <script
              type="text/javascript"
              src="//websdk.fastbooking-services.com/widgets/app.js"
            ></script>
            <style>{`
        .starting-from {
          display: flex !important;
          align-items: center !important;
          justify-content: space-around !important;
          max-width: 1000px !important;
          margin: 0 auto !important;
          padding: 1px !important;
          font-size: 13px !important;
          border: 0px solid #000 !important;
        }
        .starting-from .starting-from--price {
          font-size: 20px !important;
          color: #b0a06c !important;
        }
        .show-modal[data-v-05537ca1] {
          cursor: pointer;
          display: inline-block;
          line-height: 20px;
          min-width: 100px;
          padding: 9px 10px !important;
          text-align: center;
          vertical-align: middle;
        }
        .best-price .day--price {
          color: #008a00 !important;
          font-size: 10px !important;
        }
      `}</style>
          </Helmet>
          {/*Slider */}
          <header className="header slider-fade">
            <OwlCarousel className="owl-theme" {...options}>
              {homeGroup.groupSliders?.map((slide, index) => (
                <div
                  key={index}
                  className="item bg-img"
                  style={{
                    backgroundImage: `url(${
                      isMobile
                        ? slide.sliderPhotoMobile
                        : isTablet
                        ? slide.sliderPhotoTablet
                        : slide.sliderPhoto
                    })`,
                  }}
                  // data-overlay-dark={
                  //   slide.pageDetails?.pageBannerColorOverlayTo
                  // }
                >
                  <div className="v-middle caption">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-10 offset-md-1 text-center">
                          <Fade cascade direction="up">
                            <span>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                            </span>
                            <h4>{slide.sliderSubText}</h4>
                            <h1>{slide.sliderMainText}</h1>
                          </Fade>
                          {/*<div className="butn-light mt-30 mb-30">
                            <Link to="#." data-scroll-nav="1">
                              <span>Learn More </span>
                            </Link>
              </div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </header>
          {/*Booking Search*/}
          <BookingForm />
          {/*About */}
          <section className="about section-padding">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-md-6 mt-lg-5  mb-30 mb-lg-0 ">
                  <Fade cascade direction="up">
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <div className="section-subtitle">
                      {homeGroup.groupHomeIntro?.groupHomeIntroTitleTop}
                    </div>
                    <div className="section-title">
                      {homeGroup.groupHomeIntro?.groupHomeIntroTitle}
                    </div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${homeGroup.groupHomeIntro?.groupHomeIntroText}`,
                      }}
                    />

                    {/*<div className="reservations"><div className="icon">
                        <span className="flaticon-call"></span>
                      </div><div className="text">
                        <p>Reservation </p>
                        <Link to="tel:+2 065 359 0093/7">
                          +2 065 359 0093/7{" "}
                        </Link>
                      </div>  </div>{" "}*/}
                  </Fade>
                </div>
                <div
                  className="col col-md-3"
                  ref={tiltRef1}
                  onMouseMove={tiltHandler1.handleMove}
                  onMouseOut={tiltHandler1.handleMouseOut}
                  onMouseDown={tiltHandler1.handleMouseDown}
                  onMouseUp={tiltHandler1.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade duration="1000">
                    <img
                      src={homeGroup.groupHomeIntro?.groupHomePhoto1}
                      alt=""
                      loading="lazy"
                      className="mt-60 mb-0"
                    />
                  </Fade>
                </div>
                <div
                  className="col col-md-3"
                  ref={tiltRef2}
                  onMouseMove={tiltHandler2.handleMove}
                  onMouseOut={tiltHandler2.handleMouseOut}
                  onMouseDown={tiltHandler2.handleMouseDown}
                  onMouseUp={tiltHandler2.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade duration="1000" delay="50">
                    {" "}
                    <img
                      src={homeGroup.groupHomeIntro?.groupHomePhoto2}
                      alt=""
                      loading="lazy"
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          {/*Rooms */}
          <section className="rooms2 section-padding bg-cream" id="rooms">
            <div className="container">
              <div className="row">
                <Fade direction="left">
                  <div className="col-md-12">
                    <div className="section-subtitle">
                      <span> {homeGroup.hotels?.groupHomeHotelTitleTop}</span>
                    </div>
                    <div className="section-title">
                      {homeGroup.hotels?.groupHomeHotelTitle}
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel className="owl-theme" {...hotelsOptions}>
                    {homeGroup.hotels?.hotels?.map((hotel, index) =>
                      index % 2 === 0 ? (
                        <div className="rooms2 " key={hotel.hotelUrl}>
                          <figure>
                            <img
                              src={hotel.hotelPhotoGroup}
                              alt={hotel.hotelName}
                              className="img-fluid"
                            />
                          </figure>
                          <div className="caption padding-left">
                            <img
                              src={hotel.hotelLogoColored}
                              alt={hotel.hotelName}
                              className="img-fluid bacground-logo"
                            />
                            {hotel.startingPrice && (
                              <h3>
                                {hotel.startingPrice} $ <span>/ Night </span>
                              </h3>
                            )}

                            <h4>
                              <Link
                                to={`/${hotel.hotelUrl}`}
                                onClick={() =>
                                  localStorage.setItem(
                                    "hotelUrl",
                                    hotel.hotelUrl
                                  )
                                }
                              >
                                {hotel.hotelName}
                              </Link>
                            </h4>
                            <p>{hotel.hotelSummery}</p>
                            {/* <div className="row room-facilities">
                              <div className="col-md-4">
                                <ul>
                                  <li>
                                    <i className="flaticon-group"></i> 1-2
                                    Persons{" "}
                                  </li>
                                  <li>
                                    <i className="flaticon-wifi"></i> Free Wifi{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4">
                                <ul>
                                  <li>
                                    <i className="flaticon-bed"></i> Twin Bed{" "}
                                  </li>
                                  <li>
                                    <i className="flaticon-breakfast"></i>{" "}
                                    Breakfast{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4">
                                <ul>
                                  <li>
                                    <i className="flaticon-clock-1"></i> 200
                                    sqft Room{" "}
                                  </li>
                                  <li>
                                    <i className="flaticon-swimming"></i>{" "}
                                    Swimming Pool{" "}
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                            <hr className="border-2" />
                            <div className="info-wrapper">
                              <div className="more">
                                {hotel.hotelPmscode && (
                                  <>
                                    {" "}
                                    <div
                                      id={`fb-widget-${index}`}
                                      className="fb-widget"
                                      data-fbConfig={index}
                                    ></div>
                                    <script
                                      className="fb-widget-config"
                                      data-fbConfig={index}
                                      type="application/json"
                                    >
                                      {JSON.stringify({
                                        params: [
                                          {
                                            startingFrom: {
                                              theme: "sf--thm-1",
                                              call2action: "none",
                                              roomRateFiltering: 2,
                                              rateFilter: [],
                                              roomFilter: [""],
                                              dayRange: 180,
                                            },
                                            currency: "USD",
                                            locale: "en_GB",
                                            pricesDisplayMode: "normal",
                                            offerComputeRule: "lowerMinstay",
                                            maxAdults: 3,
                                            maxChildren: 2,
                                            mainColor: "#b0a06c",
                                            themeDark: false,
                                            openFrontInNewTab: true,
                                            property: hotel.hotelPmscode,
                                            title: hotel.hotelName,
                                            childrenMaxAge: 12,
                                            groupsNames: "egstellamakadihotels",
                                            quicksearch: {
                                              showChildrenAges: true,
                                            },
                                            fbWidget: "StartingFrom",
                                          },
                                        ],
                                        commonParams: {
                                          redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                          showPropertiesList: false,
                                          demoMode: false,
                                          allowGroupSelection: false,
                                          groupName: "",
                                        },
                                        _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                        propertyIndex: 0,
                                        version: "1.52.0",
                                        baseHost: `websdk.fastbooking-services.com`,
                                      })}
                                    </script>
                                  </>
                                )}
                              </div>
                              <div className="butn-dark">
                                {" "}
                                <Link
                                  to={`/${hotel.hotelUrl}`}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "hotelUrl",
                                      hotel.hotelUrl
                                    )
                                  }
                                >
                                  <span>Book Now </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="rooms2 left" key={hotel.hotelUrl}>
                          <figure>
                            <img
                              src={hotel.hotelPhotoGroup}
                              alt={hotel.hotelName}
                              className="img-fluid"
                            />
                          </figure>
                          <div className="caption padding-left">
                            <img
                              src={hotel.hotelLogoColored}
                              alt={hotel.hotelName}
                              className="img-fluid bacground-logo"
                            />
                            {hotel.startingPrice && (
                              <h3>
                                {hotel.startingPrice} $ <span>/ Night </span>
                              </h3>
                            )}

                            <h4>
                              <Link
                                to={`/${hotel.hotelUrl}`}
                                onClick={() =>
                                  localStorage.setItem(
                                    "hotelUrl",
                                    hotel.hotelUrl
                                  )
                                }
                              >
                                {hotel.hotelName}
                              </Link>
                            </h4>
                            <p>{hotel.hotelSummery}</p>
                            {/* <div className="row room-facilities">
                              <div className="col-md-4">
                                <ul>
                                  <li>
                                    <i className="flaticon-group"></i> 1-2
                                    Persons{" "}
                                  </li>
                                  <li>
                                    <i className="flaticon-wifi"></i> Free Wifi{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4">
                                <ul>
                                  <li>
                                    <i className="flaticon-bed"></i> Twin Bed{" "}
                                  </li>
                                  <li>
                                    <i className="flaticon-breakfast"></i>{" "}
                                    Breakfast{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4">
                                <ul>
                                  <li>
                                    <i className="flaticon-clock-1"></i> 200
                                    sqft Room{" "}
                                  </li>
                                  <li>
                                    <i className="flaticon-swimming"></i>{" "}
                                    Swimming Pool{" "}
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                            <hr className="border-2" />
                            <div className="info-wrapper">
                              <div className="more">
                                {hotel.hotelPmscode && (
                                  <>
                                    {" "}
                                    <div
                                      id={`fb-widget-${index}`}
                                      className="fb-widget"
                                      data-fbConfig={index}
                                    ></div>
                                    <script
                                      className="fb-widget-config"
                                      data-fbConfig={index}
                                      type="application/json"
                                    >
                                      {JSON.stringify({
                                        params: [
                                          {
                                            startingFrom: {
                                              theme: "sf--thm-1",
                                              call2action: "none",
                                              roomRateFiltering: 2,
                                              rateFilter: [],
                                              roomFilter: [""],
                                              dayRange: 180,
                                            },
                                            currency: "USD",
                                            locale: "en_GB",
                                            pricesDisplayMode: "normal",
                                            offerComputeRule: "lowerMinstay",
                                            maxAdults: 3,
                                            maxChildren: 2,
                                            mainColor: "#b0a06c",
                                            themeDark: false,
                                            openFrontInNewTab: true,
                                            property: hotel.hotelPmscode,
                                            title: hotel.hotelName,
                                            childrenMaxAge: 12,
                                            groupsNames: "egstellamakadihotels",
                                            quicksearch: {
                                              showChildrenAges: true,
                                            },
                                            fbWidget: "StartingFrom",
                                          },
                                        ],
                                        commonParams: {
                                          redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                          showPropertiesList: false,
                                          demoMode: false,
                                          allowGroupSelection: false,
                                          groupName: "",
                                        },
                                        _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                        propertyIndex: 0,
                                        version: "1.52.0",
                                        baseHost: `websdk.fastbooking-services.com`,
                                      })}
                                    </script>
                                  </>
                                )}
                              </div>
                              <div className="butn-dark">
                                {" "}
                                <Link
                                  to={`/${hotel.hotelUrl}`}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "hotelUrl",
                                      hotel.hotelUrl
                                    )
                                  }
                                >
                                  <span>Book Now </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
          {/*Cruises */}
          <section className="rooms1 section-padding" data-scroll-index="1">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <Fade direction="left" className="col-md-6" triggerOnce>
                  <div>
                    <div className="section-subtitle">
                      <span>{homeGroup.nileCruises?.nileCruisesTitleTop}</span>
                    </div>
                    <div className="section-title">
                      {homeGroup.nileCruises?.nileCruisesTitle}
                    </div>
                  </div>
                </Fade>
                <Fade
                  direction="right"
                  className="col-md-6 text-lg-end"
                  triggerOnce
                >
                  <Link to="/nile-cruises">
                    {" "}
                    <button
                      className={` butn-dark2  mb-5 mb-lg-0 `}
                      type="button"
                    >
                      <span>View All Cruises</span>
                    </button>
                  </Link>
                </Fade>
              </div>
              <div className="row align-items-center">
                {homeGroup.nileCruises?.nileCruises?.map((cruise, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="item">
                      <div className="position-re o-hidden">
                        <img
                          src={cruise.nileCruisePhoto}
                          alt={cruise.nileCruiseTitle}
                        />
                      </div>
                      <span className="category">
                        <Link to={`/nile-cruises#${cruise.nileCruisePhoto}`}>
                          {cruise.nileCruiseNumOfCabins} Cabins{" "}
                        </Link>
                      </span>
                      <div className="con">
                        <h5>
                          <Link to={`/nile-cruises#${cruise.nileCruiseTitle}`}>
                            {cruise.nileCruiseTitle}{" "}
                          </Link>
                        </h5>
                        <div className="line"></div>
                        <div className="row facilities">
                          <div className="col col-md-12 text-end">
                            <div className="permalink">
                              <Link
                                to={`/nile-cruises#${cruise.nileCruiseTitle}`}
                              >
                                More <i className="ti-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/*Video */}
          {homeGroup.groupHomeVideo?.groupHomeVideoUrl && (
            <section
              className="video-wrapper video section-padding bg-img bg-fixed"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? homeGroup.groupHomeVideo
                        ?.groupHomeVideoSectionBannerMobile
                    : isTablet
                    ? homeGroup.groupHomeVideo
                        ?.groupHomeVideoSectionBannerTablet
                    : homeGroup.groupHomeVideo?.groupHomeVideoSectionBanner
                })`,
                backgroundOverlay: "dark",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2 text-center">
                    <Fade cascade direction="up">
                      <span>
                        <i className="star-rating"></i>
                        <i className="star-rating"></i>
                        <i className="star-rating"></i>
                        <i className="star-rating"></i>
                        <i className="star-rating"></i>
                      </span>
                      <div className="section-subtitle">
                        <span>
                          {
                            homeGroup.groupHomeVideo
                              ?.groupHomeVideoSectionTitleTop
                          }
                        </span>
                      </div>
                      <div className="section-title">
                        <span>
                          {homeGroup.groupHomeVideo?.groupHomeVideoSectionTitle}
                        </span>
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="row">
                  <div className="text-center col-md-12">
                    <button className="vid" onClick={handleOpen}>
                      <div className="vid-butn">
                        <span className="icon">
                          <i className="ti-control-play"></i>
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId={homeGroup.groupHomeVideo?.groupHomeVideoUrl}
                onClose={handleClose}
              />
            </section>
          )}

          {/*Facilities  
          <section className="search-and-features-area bgc-lighter   rel z-1 ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-2 col-lg-3 col-md-4 col-6 col-small">
                  <div
                    className="feature-item-three wow fadeInUp delay-0-2s animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="icon">
                      <i className="flaticon-transportation-1"></i>
                    </div>
                    <h6>
                      <Link to="#.">Transportations </Link>
                    </h6>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6 col-small">
                  <div
                    className="feature-item-three wow fadeInUp delay-0-3s animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="icon">
                      <i className="flaticon-spa"></i>
                    </div>
                    <h6>
                      <Link to="#.">SPA &amp; GYM </Link>
                    </h6>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6 col-small">
                  <div
                    className="feature-item-three wow fadeInUp delay-0-2s animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="icon">
                      <span className="flaticon-wifi"></span>
                    </div>
                    <h6>
                      <Link to="#.">Fast Wifi </Link>
                    </h6>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6 col-small">
                  <div
                    className="feature-item-three wow fadeInUp delay-0-2s animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="icon">
                      <i className="flaticon-drink"></i>
                    </div>
                    <h6>
                      <Link to="#.">Food &amp; Drink </Link>
                    </h6>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6 col-small">
                  <div
                    className="feature-item-three wow fadeInUp delay-0-2s animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="icon">
                      <i className="flaticon-bathtub"></i>
                    </div>
                    <h6>
                      <Link to="#.">Bathtub </Link>
                    </h6>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-6 col-small">
                  <div
                    className="feature-item-three wow fadeInUp delay-0-2s animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="icon">
                      <i className="flaticon-swimming"></i>
                    </div>
                    <h6>
                      <Link to="#.">Swimming-pool </Link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-lines for-bg-white">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </section>*}
          {/*News */}
          {homeGroup.groupNews?.length > 0 && (
            <section className="news section-padding " id="news">
              <div className="container">
                <div className="row">
                  <Fade direction="left">
                    <div className="col-md-12">
                      <div className="section-subtitle">
                        <span>
                          {homeGroup.groupNews?.groupHomeNewsTitleTop}
                        </span>
                      </div>
                      <div className="section-title">
                        <span className="text-dark">
                          {homeGroup.groupNews?.groupHomeNewsTitle}
                        </span>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <OwlCarousel className="owl-theme" {...newsOptions}>
                      {homeGroup.groupNews?.newsList?.map((news) => (
                        <div key={news.newsUrl} className="item">
                          <div className="position-re o-hidden">
                            <img
                              src={news.newsPhoto}
                              alt={news.newsTitle}
                              className="w-100"
                            />
                            <div className="date">
                              <Link to="#."> {news.newsDateTime}</Link>
                            </div>
                          </div>
                          <div className="con">
                            {news.newsType && (
                              <span class="category">
                                <Link to="#.">{news.newsType} </Link>
                              </span>
                            )}

                            <h5>
                              <Link to="#.">{news.newsTitle}</Link>
                            </h5>
                            <hr className="border-2 my-2" />
                            <p className="text-secondary ">
                              <Link to="#.">{news.newsShortDescription}</Link>
                            </p>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default HomeGroup;
