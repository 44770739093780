import React from "react";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 | Page Not Found </title>

        <meta name="title" content="Page Not Found | Orient Hotels" />
        <meta name="description" content="404 Page Not Found" />
        <meta name="keywords" content="404, error, not found, orient hotels" />
      </Helmet>
      <section style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}>
        <h1 className="text-center text-light">Page Not Found</h1>
      </section>
    </>
  );
};

export default NotFound;
