import React, { useRef } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BookingForm from "../../components/BookingForm";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
SwiperCore.use([Pagination, Navigation]);
const Spa = () => {
  const hotelUrl = localStorage.getItem("hotelUrl");
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper;
  };
  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const rooms = [
    {
      id: 1,
      image: "r1.jpg",
      title: "Superior Room",
      price: "$499 / NIGHT",
      features: ["30M2", "Sea View", "3 Persons"],
    },
    {
      id: 2,
      image: "r2.jpg",
      title: "Pool View Room",
      price: "$499 / NIGHT",
      features: ["30M2", "Pool View", "3 Persons"],
    },
    {
      id: 3,
      image: "r3.jpg",
      title: "Family Suite",
      price: "$499 / NIGHT",
      features: ["30M2", "Pool View", "3 Persons"],
    },
    {
      id: 4,
      image: "r4.jpg",
      title: "Deluxe Room",
      price: "$499 / NIGHT",
      features: ["30M2", "Pool View", "3 Persons"],
    },
  ];

  // Custom handler to navigate to next slide
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  return (
    <>
      {" "}
      <section
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="0.3"
        style={{
          backgroundImage: `url(img/spa/3.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left caption mt-90">
              <Fade triggerOnce cascade direction="up">
                {" "}
                <h1>Spa & Wellness</h1>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <BookingForm />
      <div role="presentation" className="breadcrumbs-wrapper py-3 bg-light-1">
        <div className="container-fluid">
          <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
            <Link
              className="d-flex align-items-center"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to={`/`}
            >
              <HomeIcon
                sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                fontSize="inherit"
              />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
              to={`/${hotelUrl}`}
            >
              {hotelUrl === "stella-beach-resort-spa"
                ? "Stella Beach Resort & Spa "
                : "Stella Gardens Resort & Spa"}
            </Link>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Spa
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <section class="rooms-page section-padding" data-scroll-index="1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              {" "}
              <span>
                <i class="star-rating"></i>
                <i class="star-rating"></i>
                <i class="star-rating"></i>
                <i class="star-rating"></i>
                <i class="star-rating"></i>
              </span>
              <div class="section-subtitle">So Many Ways to </div>
              <div class="section-title">Spa & Wellness </div>
              <p class="mb-30">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Dolorum facilis dolores praesentium sunt odit nulla molestiae.
                Nulla commodi laborum culpa molestias ab! Facilis nobis vel
                similique autem blanditiis suscipit repudiandae.
              </p>
            </div>
            <div class="col-md-12">
              <div class="reservations">
                <div class="icon">
                  <span class="flaticon-call"></span>
                </div>
                <div class="text">
                  <p>Reservations </p>{" "}
                  <a href="tel:855-100-4444">855 100 4444 </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services section-padding2">
        <div class="container">
          <div class="row">
            <div class="col-md-6 p-0 ">
              <div class="img left">
                <Link to="spa-details">
                  <img src="img/spa/3.jpg" alt="" />
                </Link>
              </div>
            </div>
            <div class="col-md-6 p-0 bg-cream valign ">
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Experiences </h6>
                  </div>
                  <h4>Spa Center </h4>
                  <p>
                    Spa center inilla duiman elit finibus viverra nec lacus
                    themo the drudea misuscipit non sagie the . Viverra
                    tristique jusio the dianne onen nivami acsestion artine.{" "}
                  </p>
                  <p>
                    <span class="flaticon-clock"></span> Daily: 7.00 am 9.00 pm{" "}
                  </p>
                  <div className="butn-dark">
                    <Link to="spa-details">
                      <span>Learn More </span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 bg-cream p-0 order2 valign ">
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Modern </h6>
                  </div>
                  <h4>Fitness Center </h4>
                  <p>
                    Fitness center duiman at finibus viverra nec a themo the
                    drudea seneoice non sagie the fermen. tristique jusio the
                    ivite onen nivami acsestion augue .{" "}
                  </p>
                  <p>
                    <span class="flaticon-clock"></span> Daily: 6.00 am 9.00 pm{" "}
                  </p>
                  <div className="butn-dark">
                    <Link to="spa-details">
                      <span>Learn More </span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 p-0 order1 ">
              <div class="img">
                <Link to="spa-details">
                  <img src="img/spa/2.jpg" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 p-0 ">
              <div class="img left">
                <Link to="spa-details">
                  <img src="img/spa/1.jpg" alt="" />
                </Link>
              </div>
            </div>
            <div class="col-md-6 p-0 bg-cream valign ">
              <div class="content">
                <div class="cont text-left">
                  <div class="info">
                    <h6>Experiences </h6>
                  </div>
                  <h4>The Health Club & </h4>
                  <p>
                    Spa center inilla duiman elit finibus viverra nec lacus
                    themo the drudea misuscipit non sagie the . Viverra
                    tristique jusio the dianne onen nivami acsestion artine.{" "}
                  </p>
                  <p>
                    <span class="flaticon-clock"></span> Daily: 10.00 am 7.00 pm{" "}
                  </p>
                  <div className="butn-dark">
                    <Link to="spa-details">
                      <span>Learn More </span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Spa;
