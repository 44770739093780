import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "@mui/material";
import BookingForm from "../../components/BookingForm";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const OfferDetails = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <header className="header slider details">
        <OwlCarousel className="owl-theme" {...options}>
          <div
            className="item bg-img text-center"
            style={{
              backgroundImage: "url(img/restaurant/1.jpg)",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className="item bg-img text-center"
            style={{
              backgroundImage: "url(img/restaurant/2.jpg)",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className="item bg-img text-center"
            style={{
              backgroundImage: "url(img/restaurant/3.jpg)",
              backgroundSize: "cover",
            }}
          ></div>
        </OwlCarousel>
        <div className="arrow bounce text-center">
          <Link to="#." onClick={(e) => scrollToSection("content")}>
            {" "}
            <i className="ti-arrow-down"></i>{" "}
          </Link>
        </div>
        <div className="owl-dots">
          <button role="button" className="owl-dot active">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
        </div>
      </header>
      <BookingForm />

      <section className="rooms-page py-5 my-5" id="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left">
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">Orient Hotels</div>
              <div className="section-title">Special Offers</div>
            </div>
            <div className="col-md-12">
              <p className="mb-30">
                Led by Chef de Martin, The Restaurant is for its excellent
                cuisine unique ambience. The gorgeous room features three open
                kitchens, allowing you to the sights and sounds the culinary
                artistry on . The menu showcases both and European influences,
                with tempting selection of classic and creative dishes for to
                sample. Cheese connoisseurs be drawn to the Wine and Cheese
                Cellar, in five-meter-high glass walls, our knowledgeable staff
                can you to some of York's greatest culinary treasures.{" "}
              </p>
              <h6>Offer Include </h6>
              <ul className="list-unstyled page-list mb-30">
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i className="ti-check"></i>
                  </div>
                  <div className="page-list-text">
                    <p>24-hour usage of Fitness center</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i className="ti-check"></i>
                  </div>
                  <div className="page-list-text">
                    <p>10% discount on Planet Spa massage treatments</p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i className="ti-check"></i>
                  </div>
                  <div className="page-list-text">
                    <p>10% discount on Ilios Dive Club </p>
                  </div>
                </li>
              </ul>
              <h6>Offer Exclude </h6>
              <ul className="list-unstyled page-list mb-30">
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i className="ti-close unavailable"></i>
                  </div>
                  <div className="page-list-text">
                    <p>Breakfast: 7.00 am – 11.00 am (daily) </p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i className="ti-close unavailable"></i>
                  </div>
                  <div className="page-list-text">
                    <p>Lunch: 12.00 noon – 2.00 pm (daily) </p>
                  </div>
                </li>
                <li>
                  <div className="page-list-icon">
                    {" "}
                    <i className="ti-close unavailable"></i>
                  </div>
                  <div className="page-list-text">
                    <p>Dinner: open from 6.30 , last order at 10.00 (daily) </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferDetails;
