import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./baseUrl";

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async () => {
    const response = await axios.get(`${baseUrl}/api/PMS`);
    return response.data;
  }
);

const propertiesSlice = createSlice({
  name: "properties",
  initialState: {
    properties: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProperties.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      state.properties = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchProperties.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export default propertiesSlice.reducer;
