import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookingForm from "../../components/BookingForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsLeftRight,
  faBed,
  faExpand,
  faMaximize,
  faUpRightAndDownLeftFromCenter,
  faUsersLine,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeetings } from "./../../data/meetings/meetingsSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";
const MeetingAndEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const { meetings, loading, error } = useSelector((state) => state.meetings);
  useEffect(() => {
    dispatch(fetchMeetings(hotelUrl));
  }, [dispatch, hotelUrl]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {meetings.pageDetails?.pageMetatagTitle || "Meetings And Events"}
            </title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/en/${hotelUrl}/meetingandevents`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={meetings.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={meetings.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? meetings.pageDetails?.pageBannerMobile
                  : isTablet
                  ? meetings.pageDetails?.pageBannerTablet
                  : meetings.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    <h1>{meetings.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Meeting & Events
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h2 className="text-50 lg:text-40 md:text-30">
                      {meetings.pageDetails?.pageTitle}
                    </h2>
                    <p>{meetings.pageDetails?.pageText}</p>
                  </Fade>
                </div>
              </div>
            </div>
          </section>

          <section className="pb-5 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {meetings.meetingEvent?.map((item, index) =>
                    index % 2 === 0 ? (
                      <div
                        className="rooms2 mb-90 "
                        key={index}
                        id={item.facilityUrl}
                      >
                        <figure>
                          <img
                            src={item.facilityPhoto}
                            alt={item.facilityName}
                            className="img-fluid"
                          />
                        </figure>
                        <div className="caption">
                          <h6>
                            <b>{item.meetingEventsType}</b>
                          </h6>
                          <h4>
                            <Link
                              to={`${
                                item.isMoreDetails
                                  ? `/${item.hotelUrl}/meetingandevents/${item.facilityUrl}`
                                  : `/${item.hotelUrl}/meetingandevents#${item.facilityUrl}`
                              }`}
                            >
                              {item.facilityName}
                            </Link>
                          </h4>{" "}
                          <div className="row my-3">
                            <div className="d-flex flex-wrap row">
                              <div className="d-flex w-auto   align-items-center">
                                {item.meetingSize && (
                                  <>
                                    <b className="me-1">
                                      <FontAwesomeIcon
                                        icon={faMaximize}
                                        className="me-1"
                                      />
                                      Size:{" "}
                                    </b>
                                    {item.meetingSize}
                                  </>
                                )}
                              </div>
                              <div className="d-flex w-auto   align-items-center">
                                {item.meetingWidths && (
                                  <>
                                    {" "}
                                    <b className="me-1">
                                      <FontAwesomeIcon
                                        icon={faArrowsLeftRight}
                                        className="me-2"
                                      />
                                      Width:{" "}
                                    </b>
                                    {item.meetingWidths}
                                  </>
                                )}
                              </div>

                              {item.meetingCellingHeight && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b className="me-1">
                                    <FontAwesomeIcon
                                      icon={faUpRightAndDownLeftFromCenter}
                                      className="me-1"
                                    />
                                    Celling Height:{" "}
                                  </b>
                                  {item.meetingCellingHeight}
                                </div>
                              )}
                              {item.meetingCapacity && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b className="me-1">
                                    <FontAwesomeIcon
                                      icon={faUsersLine}
                                      className="me-1"
                                    />{" "}
                                    Capacity:{" "}
                                  </b>{" "}
                                  {item.meetingCapacity}
                                </div>
                              )}
                            </div>
                          </div>
                          <p>{item.facilitySummery}</p>
                          <hr className="border-2" />
                          {item.isMoreDetails && (
                            <div className="info-wrapper">
                              <div className="more">
                                <Link
                                  to={`/${item.hotelUrl}/meetingandevents/${item.facilityUrl}`}
                                  className="link-btn"
                                >
                                  Details <i className="ti-arrow-right"></i>
                                </Link>
                              </div>
                              <div className="butn-dark">
                                {" "}
                                <Link
                                  to={`/${item.hotelUrl}/meetingandevents/${item.facilityUrl}`}
                                >
                                  <span>Learn More</span>
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="rooms2 left mb-90 "
                        key={index}
                        id={item.facilityUrl}
                      >
                        <figure>
                          <img
                            src={item.facilityPhoto}
                            alt={item.facilityName}
                            className="img-fluid"
                          />
                        </figure>
                        <div className="caption">
                          <h6>
                            <b>{item.meetingEventsType}</b>
                          </h6>
                          <h4>
                            <Link
                              to={`${
                                item.isMoreDetails
                                  ? `/${item.hotelUrl}/meetingandevents/${item.facilityUrl}`
                                  : `/${item.hotelUrl}/meetingandevents#${item.facilityUrl}`
                              }`}
                            >
                              {item.facilityName}
                            </Link>
                          </h4>{" "}
                          <div className="row my-3">
                            <div className="d-flex flex-wrap row">
                              {item.meetingSize && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b className="me-1">
                                    <FontAwesomeIcon
                                      icon={faMaximize}
                                      className="me-1"
                                    />
                                    Size:{" "}
                                  </b>
                                  {item.meetingSize}
                                </div>
                              )}
                              {item.meetingWidths && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b className="me-1">
                                    <FontAwesomeIcon
                                      icon={faArrowsLeftRight}
                                      className="me-2"
                                    />
                                    Width:{" "}
                                  </b>
                                  {item.meetingWidths}
                                </div>
                              )}

                              {item.meetingCellingHeight && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b className="me-1">
                                    <FontAwesomeIcon
                                      icon={faUpRightAndDownLeftFromCenter}
                                      className="me-1"
                                    />
                                    Celling Height:{" "}
                                  </b>
                                  {item.meetingCellingHeight}
                                </div>
                              )}
                              {item.meetingCapacity && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b className="me-1">
                                    <FontAwesomeIcon
                                      icon={faUsersLine}
                                      className="me-2"
                                    />{" "}
                                    Capacity:{" "}
                                  </b>{" "}
                                  {item.meetingCapacity}
                                </div>
                              )}
                            </div>
                          </div>
                          <p>{item.facilitySummery}</p>
                          <hr className="border-2" />
                          {item.isMoreDetails && (
                            <div className="info-wrapper">
                              <div className="more">
                                <Link
                                  to={`/${item.hotelUrl}/meetingandevents/${item.facilityUrl}`}
                                  className="link-btn"
                                >
                                  Details <i className="ti-arrow-right"></i>
                                </Link>
                              </div>
                              <div className="butn-dark">
                                {" "}
                                <Link
                                  to={`/${item.hotelUrl}/meetingandevents/${item.facilityUrl}`}
                                >
                                  <span>Book Now </span>
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MeetingAndEvents;
