import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./baseUrl";

export const subscribeNewsletter = createAsyncThunk(
  "newsLetter/subscribe",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/ContactUs/Newsletter-Submit?request=${email}`,
        {
          request: email,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : "An error occurred"
      );
    }
  }
);

const newsLetterSlice = createSlice({
  name: "newsLetter",
  initialState: {
    loadingNewsletter: false,
    errorNewsletter: null,
    subscribed: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribeNewsletter.pending, (state) => {
        state.loadingNewsletter = true;
        state.errorNewsletter = null;
      })
      .addCase(subscribeNewsletter.fulfilled, (state) => {
        state.loadingNewsletter = false;
        state.subscribed = true;
      })
      .addCase(subscribeNewsletter.rejected, (state, action) => {
        state.loadingNewsletter = false;
        state.errorNewsletter = action.error.message;
        state.subscribed = false;
      });
  },
});

export default newsLetterSlice.reducer;
